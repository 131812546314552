/*------ Media object ------*/

.media {
	margin-top: 15px;
	zoom: 1;
	overflow: hidden;
}

.media-body {
	zoom: 1;
	overflow: hidden;
	width: 10000px;
	-ms-flex: 1;
	flex: 1;
}

.media-object {
	display: block;
}

.media img {
	width: 50px;
	height: 50px;
}

.media-right,
.media>.pull-right {
	padding-left: 10px;
}

.media-body,
.media-left,
.media-right {
	display: block;
	vertical-align: top;
}

.media-left,
.media>.pull-left {
	padding-right: 10px;
}

.media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.media-body h4 {
	font-weight: 500;
	font-size: 16px;
}

.media-middle {
	vertical-align: middle;
}

.media-bottom {
	vertical-align: bottom;
}

.list-media {
	.media-img {
		position: relative;
		float: left;
		width: 48px;
		margin-right: 20px;
	}
	.info {
		padding-left: 55px;
		min-height: 40px;
		height: auto;
		position: relative;
		h4 a {
			font-size: 16px;
			margin-top: 10px;
		}
		p {
			font-size: 13px;
		}
		.text-right {
			right: 0;
			color: #8a8a8a;
			top: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-top: -28px;
		}
	}
}

.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}

.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.media {
	display: flex;
	align-items: flex-start;
}

.mediaicon {
	font-size: 18px;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 9px 0px;
	border-radius: 20px;
}

.mediafont {
	font-size: 16px;
	font-weight: 600;
}

.media {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
}

.close {
	float: right;
	font-size: 1.40625rem;
	font-weight: 700;
	line-height: 1;
	color: $black;
	text-shadow: 0 1px 0 $white;
	opacity: .5;
	transition: .3s color;
	&:hover,
	&:focus {
		color: $black;
		text-decoration: none;
		opacity: .75;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}