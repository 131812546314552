.fs-10 {
	font-size: 10px !important;
}
.fs-13 {
	font-size: 13px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-17 {
	font-size: 17px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-23 {
	font-size: 23px !important;
}
.fs-25 {
	font-size: 25px !important;
}
.fs-30 {
	font-size: 30px !important;
}
.fs-40 {
	font-size: 40px;
}
.fs-50 {
	font-size: 50px;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.fw-600 {
	font-weight: 600;
}
.font-weight-semibold {
	font-weight: 500 !important;
}
.font-weight-bold {
	font-weight: 700 !important;
}
.font-italic {
	font-style: italic !important;
}