.modal-header h4.modal-title {
	font-weight: 300;
}
.modal-body label {
	font-weight: 400;
}
/*------ Modal -------*/

.modal-open {
	overflow: hidden;
	padding: 0 !important;
	display: block;
}
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	outline: 0;
	padding-right: 0 !important;
	margin: 0 !important;
}
.modal-open, .modal-backdrop.fade.show {
	padding-right: 0 !important;
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal {
	&.fade .modal-dialog {
		transition: -webkit-transform 0.3s ease-out;
		transition: transform 0.3s ease-out;
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
		-webkit-transform: translate(0, -25%);
		transform: translate(0, -25%);
	}
	&.show .modal-dialog {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}
.modal-dialog-centered {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #eaedf1;
	border-radius: 3px;
	outline: 0;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: $black;
	&.fade {
		opacity: 0;
	}
	&.show {
		opacity: 0.5;
	}
}
.modal-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid #eaedf1;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	.close {
		padding: 1rem;
		margin: -1rem -1rem -1rem auto;
	}
}
.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}
.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem;
	p:last-child {
		margin-bottom: 0;
	}
}
.modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #e9ecef;
	> {
		:not(:first-child) {
			margin-left: .25rem;
		}
		:not(:last-child) {
			margin-right: .25rem;
		}
	}
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}
	.modal-sm {
		max-width: 300px;
	}
}
@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}
.model-wrapper-demo {
	padding: 50px 0;
	background: #f1f1f9;
}