/*-----Jumbotron-----*/

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #f1f1f9;
}
@media (min-width: 576px) {
	.jumbotron {
		padding: 2rem;
	}
}
.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}