/*------ Email services -------*/

.mail-box {
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
	table-layout: fixed;
	width: 100%;
	aside {
		display: table-cell;
		float: none;
		height: 100%;
		padding: 0;
		vertical-align: top;
	}
	.sm-side {
		background: none repeat scroll 0 0 #e5e8ef;
		border-radius: 4px 0 0 4px;
		width: 25%;
	}
	.lg-side {
		background: none repeat scroll 0 0 $white;
		border-radius: 0 4px 4px 0;
		width: 75%;
	}
	.sm-side .user-head {
		background: none repeat scroll 0 0 #00a8b3;
		border-radius: 4px 0 0;
		color: $white;
		min-height: 80px;
		padding: 10px;
	}
}

.user-head {
	.inbox-avatar {
		float: left;
		width: 65px;
		img {
			border-radius: 4px;
		}
	}
	.user-name {
		display: inline-block;
		margin: 0 0 0 10px;
		h5 {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 0;
			margin-top: 15px;
			a {
				color: $white;
			}
		}
		span a {
			color: #87e2e7;
			font-size: 12px;
		}
	}
}

a.mail-dropdown {
	background: none repeat scroll 0 0 #80d3d9;
	border-radius: 2px;
	color: #01a7b3;
	font-size: 10px;
	margin-top: 20px;
	padding: 3px 5px;
}

ul.inbox-nav {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.inbox-divider {
	border-bottom: 1px solid #d5d8df;
}

ul.inbox-nav li {
	display: inline-block;
	line-height: 45px;
	width: 100%;
	a {
		color: #6a6a6a;
		display: inline-block;
		line-height: 45px;
		padding: 0 20px;
		width: 100%;
		&:hover {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
	}
	&.active a {
		background: none repeat scroll 0 0 #d5d7de;
		color: #6a6a6a;
	}
	a {
		&:focus {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
		i {
			color: #6a6a6a;
			font-size: 16px;
			padding-right: 10px;
		}
		span.label {
			margin-top: 13px;
		}
	}
}

.inbox-head {
	border-radius: 0 4px 0 0;
	padding: 10px;
	border-radius: 3px;
	h3 {
		display: inline-block;
		font-weight: 300;
		margin: 0;
	}
	.sr-input {
		border: medium none;
		border-radius: 4px 0 0 4px;
		box-shadow: none;
		color: #8a8a8a;
		float: left;
		height: 30px;
		padding: 0 10px;
	}
	.sr-btn {
		background: none repeat scroll 0 0 #1643A3;
		border: medium none;
		border-radius: 0 4px 4px 0;
		color: $white;
		height: 30px;
		padding: 0 20px;
	}
}

ul.inbox-pagination {
	float: right;
	li {
		float: left;
	}
}

.mail-option {
	display: inline-block;
	margin-bottom: 10px;
	width: 100%;
	.chk-all,
	.btn-group {
		margin-right: 5px;
	}
	.chk-all,
	.btn-group a.btn {
		background: none repeat scroll 0 0 #f1f1f9;
		border: 1px solid #e7e7e7;
		border-radius: 3px !important;
		color: $black;
		display: inline-block;
		padding: 5px 10px;
	}
}

.inbox-pagination a.np-btn {
	background: none repeat scroll 0 0 #f1f1f9;
	border: 1px solid #e7e7e7;
	border-radius: 3px !important;
	color: #afafaf;
	display: inline-block;
	padding: 5px 15px;
	margin-left: 5px;
}

.mail-option {
	.chk-all input[type="checkbox"] {
		margin-top: 0;
	}
	.btn-group a.all {
		border: medium none;
		padding: 0;
	}
}

.inbox-pagination li span {
	display: inline-block;
	margin-right: 5px;
	margin-top: 7px;
}

.inbox-body .modal .modal-body {
	input,
	textarea {
		border: 1px solid #e6e6e6;
		box-shadow: none;
	}
}

.heading-inbox h4 {
	border-bottom: 1px solid #ddd;
	color: #444;
	font-size: 18px;
	margin-top: 20px;
	padding-bottom: 10px;
}

.sender-info {
	margin-bottom: 20px;
	img {
		height: 30px;
		width: 30px;
	}
}

.sender-dropdown {
	background: none repeat scroll 0 0 #eaedf1;
	color: #777;
	font-size: 10px;
	padding: 0 3px;
}

.view-mail a {
	color: #ff6c60;
}

.attachment-mail {
	margin-top: 30px;
	ul {
		display: inline-block;
		margin-bottom: 30px;
		width: 100%;
		li {
			float: left;
			margin-bottom: 10px;
			margin-right: 10px;
			width: 150px;
			img {
				width: 100%;
			}
			span {
				float: right;
			}
		}
	}
	.file-name {
		float: left;
	}
	.links {
		display: inline-block;
		width: 100%;
	}
}

hr.message-inner-separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}
	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}
	.messages-left .card-header {
		display: none;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.form-control.header-search {
		margin-top: -3px !important;
	}
}

@media (max-width: 990px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.messages-left .card-header {
		display: none;
	}
	.messages-list {
		top: 24px !important;
	}
}


/*------Email---------*/

.mail-chats {
	height: 100%;
	min-height: 0;
	border-top: 1px solid rgba(128, 128, 128, 0.16);
	margin-top: 10px;
	padding: 10px 50px 10px 0;
	width: 100%;
	list-style-type: none;
	flex-direction: column !important;
	display: flex !important;
	li.chat-persons {
		padding: 10px;
		display: block;
	}
}

li.chat-persons a {
	text-decoration: none;
}

.mail-chats li.chat-persons a {
	span.pro-pic {
		img {
			max-width: 100%;
			width: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			border-radius: 100%;
			flex-direction: column !important;
		}
		display: inline-block;
		padding: 0;
		width: 20%;
		max-width: 40px;
		float: left;
		margin-right: 20px;
	}
	div.user {
		flex-direction: column !important;
	}
}

ul.mail-chats li.chat-persons a div.user {
	display: flex !important;
}

.mail-chats li.chat-persons.user {
	width: 80%;
	padding: 5px 10px 0px 15px;
	flex-direction: column !important;
	display: flex !important;
}

.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	color: #9aa0ac;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

.chat {
	outline: 0;
	margin: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}

.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}

.chat-message {
	position: relative;
	display: inline-block;
	background-color: #467fcf;
	color: $white;
	font-size: 0.875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid #467fcf;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}

.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: #f3f3f3;
		color: #495057;
		margin-left: .5rem;
		margin-right: 2.5rem;
		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid #f3f3f3;
		}
	}
}

#messages-main {
	position: relative;
	margin: 0 auto;
	&:after,
	&:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid #eee;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
	}
	.ms-user {
		padding: 15px;
		background: #f8f8f8;
		>div {
			overflow: hidden;
			padding: 3px 5px 0 15px;
			font-size: 11px;
		}
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

@media (max-width: 767px) {
	#messages-main {
		.ms-menu {
			height: calc(100% - 58px);
			display: none;
			z-index: 1;
			top: 58px;
			&.toggled {
				display: block;
			}
		}
		.ms-body {
			overflow: hidden;
		}
	}
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: "";
	}
}

.message-feed {
	padding: 10px;
	&.right>.pull-right {
		margin-left: 15px;
	}
	&:not(.right) .mf-content {
		color: $white;
	}
	&.right .mf-content {
		background: #f1f1f9;
	}
}

.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: #f1f1f9;
		right: -20px;
		top: 11px;
	}
}

.mf-date {
	display: block;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}

.msb-reply {
	position: relative;
	margin-top: 3px;
	background: $white;
	padding: 10px;
	border-radius: 0 7px 7px 7px;
}

.four-zero,
.lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}
	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: $white;
		border-radius: 0 3px 3px 0;
		line-height: 36px;
		position: absolute;
	}
}

#ms-menu-trigger {
	text-align: right;
}

.message-feed {
	&.right {
		text-align: right;
	}
	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}


/*=====================================
Inbox Message Style
=======================================*/

.inbox-message {
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			position: relative;
			padding: 15px 20px;
			border-bottom: 1px solid #e4e4e4;
			&:hover,
			&:focus {
				background: #eeeeee;
			}
		}
	}
	.message-avatar {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.message-avatar img {
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.inbox-message .message-body {
	margin-left: 85px;
	font-size: 15px;
	color: #999999;
}

.message-body-heading h5 {
	font-weight: 600;
	display: inline-block;
	color: #999999;
	margin: 0 0 7px 0;
	padding: 0;
}

.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: 12px;
	color: $white;
	font-style: normal;
	padding: 4px 10px;
	margin-left: 5px;
	margin-top: -5px;
	&.unread {
		background: #07b107;
	}
	&.important {
		background: #dd2027;
	}
	&.pending {
		background: #11a3dd;
	}
}

.message-body-heading span {
	float: right;
	font-size: 14px;
}

.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: 15px;
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending,
		&.unread {
			display: none;
		}
	}
}

.social-card-header {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 96px;
	i {
		font-size: 32px;
		color: #FFF;
	}
}


/*--chat--*/

.chat {
	margin-bottom: auto;
	.card {
		height: 600px;
	}
	.card-header {
		padding: 1rem;
	}
}

.contacts_body {
	white-space: nowrap;
}

.msg_card_body {
	overflow-y: auto;
}

.contacts_body {
	.contacts {
		list-style: none;
		padding: 0;
		li {
			width: 100% !important;
			padding: 15px;
			border-bottom: 1px solid #eaedf1;
		}
	}
	.active {
		background-color: #f1f1f9;
	}
}

.chatbox {
	.user_img {
		height: 50px;
		width: 50px;
		border: 1.5px solid #f5f6fa;
	}
	.user_img_msg {
		height: 40px;
		width: 40px;
		border: 1.5px solid #f5f6fa;
	}
	.img_cont {
		position: relative;
		height: 50px;
		width: 50px;
	}
	.img_cont_msg {
		height: 40px;
		width: 40px;
		display: contents;
	}
}

.chat-box-single-line {
	height: 12px;
	margin: 7px 0 30px;
	position: relative;
	text-align: center;
}

.chat abbr.timestamp {
	padding: 4px 14px;
	background: #e3e0ea;
	border-radius: 4px;
}

.chatbox {
	.user_info {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 15px;
		span {
			color: #464444;
		}
	}
	.action-header small {
		font-size: 10px;
	}
}

.chat {
	.video_cam {
		margin-left: 50px;
		margin-top: 5px;
		span {
			color: white;
			font-size: 20px;
			cursor: pointer;
			margin-right: 20px;
		}
	}
	.msg_cotainer {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 10px;
		background-color: #f1f1f9;
		padding: 10px;
		position: relative;
	}
	.msg_cotainer_send {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 10px;
		background-color: #e3e0ea;
		padding: 10px;
		position: relative;
	}
	.msg_time {
		position: absolute;
		left: 0;
		bottom: -18px;
		color: #7886a0;
		font-size: 10px;
	}
	.msg_time_send {
		position: absolute;
		right: 0;
		bottom: -18px;
		color: #7886a0;
		font-size: 10px;
	}
	.msg_head {
		position: relative;
		box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
	}
	.action-header {
		position: relative;
		background: $white;
		padding: 15px 13px 15px 17px;
		border-bottom: 1px solid #eaedf1;
	}
	.dropdown-menu.dropdown-menu-right.show {
		width: 100%;
		margin-bottom: 5px;
		position: absolute;
		transform: translate3d(-176px, 22px, 0px);
		top: 10px !important;
		left: 0px;
		will-change: transform;
	}
	.msb-reply textarea {
		width: 100%;
		font-size: 13px;
		padding: 12px 47px;
		resize: none;
		height: 44px;
		border: 1px solid #eaedf1;
		background: #f1f1f9;
		margin-left: -42px;
		color: #76839a;
	}
	.attach_btn {
		border: 0 !important;
		cursor: pointer;
		z-index: 1000;
		margin-left: 0px;
	}
	.dropdown-menu li {
		padding: 7px;
		color: #76839a;
		&:hover {
			background: #f1f1f9;
		}
		i {
			padding: 7px;
		}
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black-2;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 2px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}